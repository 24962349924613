// @import "../../public/styles/variables";
//@import "../../../public/styles/mixins";

.container{
  position: relative;

  // color: $primary;
  .autocomplete_dropdown_container{
    margin-top: .3em;
    position: absolute;
    z-index: 200;
    background-color: white;
    width: 100%;
    border:1px solid black;
    padding: .5em;
    border-radius:6px;

    div{
      cursor: pointer;
    }
    :not(:last-child){
      margin-bottom: .5em;

    }
  }
}


