.notif {
  margin-right: 100px;
}
.cc {
  background-color: rgb(26, 10, 51);
}
.titre {
  text-align: center;
}
.flexx {
  display: flex;
}
.open_wheel {
  width: 150px;
  position: absolute;
}
.titree {
  margin-left: 45px;
  margin-top: 1px;
}
