.image {
  width: 350px;
  height: 350px;
  margin-right: 150px;
}
.imagee {
  width: 350px;
  height: 350px;
  margin-right: 150px;
  margin-bottom: 95px;
}
.button_sign {
  background-color: #270a36;
}
.button_sign:hover {
  background-color: #270a36;
}
.logo_openflow {
  width: 200px;
}
.tout {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
@media only screen and (max-width: 1090px) and (min-width: 320px) {
  .image {
    display: none;
  }
  .imagee {
    display: none;
  }
  .captcha {
    margin-left: 0;
  }
}
